<template>
  <list-template
      :search-config="searchConfig"
      :table-config="tableConfig"
      :table-data="tableData"
      :total="total"
      :loading="loading"
      has-add
      @onAdd="$router.push('./add')"
      :current-page="page"
      @onSearch="onSearch"
      @onReset="search=null"
      @onChangePage="changeCurrentPage"
      @onHandle="tableHandle"
  ></list-template>
</template>

<script>
import {mapGetters,mapState} from "vuex";

export default {
	_config:{"route":{"path":"list","meta":{"title":"列表","keepAlive":true}}},
  data(){
    return {
      loading:false,
      searchConfig:[
        {
          placeholder:"搜索考试名称",
          prop:"filter[and][0][name][like]",
        }
      ],
      tableConfig:[
        {
          prop:"name",
          label:"考试名称",
        },
        {
          prop:"type",
          label:"考试类型"
        },
        {
          prop:"creator",
          label:"创建人",
          width:"150rem",

        },
        {
          prop:"created_at",
          label:"创建时间",
        },
        {
          prop:"handle",
          label:"操作",
          width:"140rem",
          handle:true,
          buttons: (row) => {
            if (this.$store.getters.userInfo.userinfo.id == row.creator_id) {
              return [{ type:"edit", text:"编辑" }, { type:"delete", text:"删除" }]
            }
            return [{ type:"view", text:"查看" }]
          }
        },
      ],
      search:null,
      total:0,
      tableData:[],

    }
  },
  computed:{
    ...mapState(["page"])
  },
  methods:{

    tableHandle(row,config){
      const { type } = config
      switch (type){
        case "edit":
          this.$router.push({path:"./edit",query:{id:row.id}});
          break;
        case "view":
          this.$router.push({path:"./show",query:{id:row.id}});
          break;
        case "delete":
          this.$confirm("删除后，相关数据将会清除","提示",{
            confirmButtonText:"删除",
            cancelButtonText:"点错了",
            type:"warning"
          }).then(()=>{
            this.$_axios.delete("/exam/"+row.id,{
              params:{
                version:row.version
              },
            }).then(res=>{
              this.getData();
              this.$message.success("删除成功")
            }).catch(()=>{})
          }).catch(()=>{});
          break;
      }
    },
    // 分页被更改
    changeCurrentPage(e) {
      this.$store.commit("setPage",e);
      this.getData();
    },
    // 获取数据
    getData(){
      let {search,page} = this;
      this.loading = true;
      this.$_axios.get("/exam",{params: {...search,sort:"-created_at",page}}).then(res=>{
        let {data} = res.data;
        this.tableData = data;
        let {total} = this.$tools.getPaginationInfo(res.headers);
        this.total = total;
      }).finally(()=>this.loading = false)
    },
    // 搜索功能
    onSearch(val){
      this.$store.commit("setPage",1);
      this.search = val;
      this.getData();
    }
  },
  mounted() {
    this.$store.commit("setPage",1);
    this.getData();
  },
  activated() {
    this.getData();
  },
}
</script>

<style scoped>

</style>
